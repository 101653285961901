.dropdown-print .header {
  margin: 7px 15px 10px 15px;
  cursor: pointer;
}
.dropdown-print .printers-list {
  width: 320px;
  padding: 0 10px;
  min-height: 560px;
  border: none;
  position: absolute;
  box-shadow: 0 1px 4px rgba(17, 43, 66, 0.1), 0 2px 6px rgba(17, 43, 66, 0.08);
}
.dropdown-print .printers-list .printer-list-header {
  margin: 0 10px;
  border-bottom: 1px solid var(--border-color);
}
.dropdown-print .printers-list .printer-list-body .station-item {
  margin: 12px 0;
}
.dropdown-print .printers-list .printer-list-body .station-item .station-status {
  align-self: center;
  flex-shrink: 0;
  justify-self: end;
  margin-right: 10px;
}
.dropdown-print .printers-list .printer-list-body .station-item .station-title {
  color: var(--text-light);
  font-size: var(--text-md);
  font-weight: 500;
}
.dropdown-print .printers-list .printer-list-body .station-active {
  box-shadow: 0 4px 5px -4px rgba(59, 130, 246, 0.2509803922), 0 0 6px rgba(59, 130, 246, 0.2509803922);
}
.dropdown-print .printers-list .printer-list-body .station-unavailable {
  box-shadow: 0 4px 5px -4px rgba(239, 68, 68, 0.2509803922), 0 0 6px rgba(239, 68, 68, 0.2509803922);
}
.dropdown-print .printers-list .printer-list-body .station-inactive {
  box-shadow: 0 1px 4px rgba(17, 43, 66, 0.1), 0 2px 6px rgba(17, 43, 66, 0.08);
}
.dropdown-print .printers-list .printer-list-body .printer-null-state {
  min-height: 500px;
  display: flex;
  align-items: center;
  place-content: center;
}
.dropdown-print .printers-list .printer-list-body .printer-null-state .title {
  margin-top: var(--margin-lg);
  font-size: var(--text-base);
  font-weight: 600;
}
.dropdown-print .printers-list .printer-list-body .printer-null-state .subtitle {
  margin-top: var(--margin-sm);
  font-size: var(--text-md);
  color: var(--text-muted);
}
.dropdown-print .printer-item-tabs {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-print .printer-item-tabs .printers-category {
  padding: var(--padding-md) 0;
  margin-bottom: -1px;
  color: var(--text-light);
  font-weight: var(--text-bold);
  cursor: pointer;
}
.dropdown-print .printer-item-tabs .printers-category.active {
  color: var(--text-color);
  border-bottom: 1px solid var(--primary-color);
}
.dropdown-print .printer-badge {
  background: var(--navbar-bg);
  align-items: center;
  display: flex;
  border-radius: 100%;
  font-size: 10px;
  height: 15px;
  justify-content: center;
  min-width: 15px;
  position: absolute;
  left: -6px;
  top: -6px;
}